import React from "react";
import Layout from "../components/Layout";
import Features from "../components/Features";
import Seo from "../components/Seo";
import PageHeader from "../components/PageHeader";
import Section from "../components/Section";

const contacts = [
  {
    title: "Allgemeine Anfragen",
    desc: (
      <div>
        <p>
          <b>E-Mail:</b>{" "}
          <a href="mailto:kontakt@comp-air.at">kontakt@comp-air.at</a>
        </p>
      </div>
    ),
  },
  {
    title: "Presse",
    desc: (
      <div>
        <p>
          <b>Name: </b> Alexander Lampalzer
        </p>
        <p>
          <b>E-Mail:</b>{" "}
          <a href="mailto:alexander.lampalzer@comp-air.at">
            alexander.lampalzer@comp-air.at
          </a>
        </p>
        <p>
          <b>Tel-Nr.:</b> <a href="tel:+436781217397">+43 678 121 739 7</a>
        </p>
      </div>
    ),
  },
];

const imprints = [
  {
    title: "Verein zur Förderung von Jugendlichen durch Robotikwettbewerbe",
    desc: (
      <div>
        <p>
          <b>Vereinssitz:</b> Anzengrubergasse 2A, 2700 Wiener Neustadt, Österreich
        </p>
        <p>
          <b>Zuständigkeit:</b> LPD NÖ, PK WN
        </p>
        <p>
          <b>ZVR-Zahl:</b> 1778145836
        </p>
      </div>
    ),
  },
];

const Contact = ({ location }) => {
  return (
    <Layout>
      <Seo
        title="robo4you - Kontakt."
        description="So können Sie uns erreichen."
        url={location.pathname}
        section="Kontakt"
      />
      <Section>
        <PageHeader
          title="Kontakt"
          // desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        />
        <Features
          title="Kontakt"
          subtitle="Immer für Sie da."
          items={contacts}
          twoCols
        />
        <Features
          title="Impressum"
          subtitle="Transparent"
          items={imprints}
          twoCols
        />
      </Section>
    </Layout>
  );
};

export default Contact;

import React from "react";
import Section from "../Section";

/**
 * See https://tailwindui.com/img/components/feature-sections.04-feature-list-xl.png
 */
const Features = ({ title, subtitle, desc, items, background, twoCols }) => {
  return (
    <Section
      background={background}
      className={`grid md:grid-cols-2 ${
        twoCols && "md:grid-cols-3"
      } grid-rows-auto gap-8`}
    >
      <div className="md:row-span-2">
        <h2 className="mb-0 text-gradient bg-gradient-to-r from-primary to-primary-dark text-sm uppercase font-bold tracking-wide text-sm md:text-lg">
          {subtitle}
        </h2>
        <h1 className="text-2xl font-bold mt-0">{title}</h1>
        <p>{desc}</p>
      </div>
      {items.map((item) => (
        <div key={item.title}>
          <h2 className="text-gray-800 text-lg font-semibold">{item.title}</h2>
          <p className="text-gray-500 text-md mt-1">{item.desc}</p>
        </div>
      ))}
    </Section>
  );
};

export default Features;
